import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { Result } from 'src/app/shared/data/result';
import { NewDocument } from 'src/app/shared/model/document';
import { DocumentService } from 'src/app/shared/services/document.service';
import { DossierService } from 'src/app/shared/services/dossier.service';
import { ElkService } from 'src/app/shared/services/elk.service';


@Component({
  selector: 'app-dwt',
  templateUrl: './dwt.component.html',
  styleUrls: ['./dwt.component.css']
})
export class DwtComponent implements OnInit {
  DWObject: WebTwain;
  selectSources: HTMLSelectElement;
  containerId = 'dwtcontrolContainer';
  edit = true;
  columns: JSON[];
  totalImages: number;
  currentImageIndex: number;
  public scanForm: FormGroup;
  curMeta: JSON;
  disableSave: boolean;
  colors: number[] = [];
  document: NewDocument;
  progress: number = 0;
  buffer = [];
  constructor(private documentService: DocumentService,
    private dossierService: DossierService,
    private elkService: ElkService,
    @Inject(MAT_DIALOG_DATA) public data: JSON[]) {
    this.columns = data;
  }

  ngOnInit(): void {
    this.newdoc();
    //Initilaiisation du viewer
    Dynamsoft.DWT.Containers = [{ WebTwainId: 'dwtObject', ContainerId: this.containerId, Width: '400px', Height: '500px' }];
    Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => { this.Dynamsoft_OnReady(); });
    Dynamsoft.DWT.ResourcesPath = '/assets/dwt-resources';
    this.initForm();
    // Configuration de la clé de licence
    Dynamsoft.DWT.ProductKey = "t0153KQMAADt5Taekp8vPAcbtV/MtGbZmVOG/Dy0bZuvJgBlsjw9FoP1o9Ji2Tau5xK/XJfrtkpz87xFNXqqMs4+z8EEmDclDZW4YgUF1Ub6VbJLO8GGebrQZXNT5G3/ryrxwXAzqdIYXjM6N92dswsy/HnKcuTe8YHRuwsydiftckv3jW0F7y0wjwwtG56ZlPhtVUcijfgD7KZ6q"
    // Recherche des périphériques de numérisation
    let checkScript = () => {

      if (Dynamsoft.Lib.detect.scriptLoaded) {

        Dynamsoft.DWT.Load();

      } else {
        setTimeout(() => checkScript(), 100);
      }
    };
    checkScript();

  }
  first() {
    this.DWObject.Viewer.first();
    this.currentImageIndex = this.DWObject.CurrentImageIndexInBuffer + 1;
  }
  next() {
    this.DWObject.Viewer.next();
    this.currentImageIndex = this.DWObject.CurrentImageIndexInBuffer + 1;
  }
  previous() {
    this.DWObject.Viewer.previous();
    this.currentImageIndex = this.DWObject.CurrentImageIndexInBuffer + 1;
  }
  last() {
    this.DWObject.Viewer.last();
    this.currentImageIndex = this.DWObject.CurrentImageIndexInBuffer + 1;
  }
  gotoPage(i) {
    this.DWObject.Viewer.gotoPage(i);
    this.currentImageIndex = this.DWObject.CurrentImageIndexInBuffer + 1;
  }

  getTotalImage() {
    return this.DWObject.HowManyImagesInBuffer;
  }

  initForm() {
    this.scanForm = new FormGroup({
      resolution: new FormControl("2"),
      type: new FormControl("pdf"),
      pixeltype: new FormControl("2"),
      duplex: new FormControl("O")
    });
  }
  newdoc() {
    //console.log("dossierId=>",this.dossierService.currentDossierId);
    var document: NewDocument = new NewDocument(this.dossierService.currentDossierId);
    this.documentService.create(document).subscribe(doc => {
      this.curMeta = JSON.parse(this.toJsonString(doc));
      this.document = doc;
      console.log("dans creation doc");
      //this.initForm()
    })
    //this.selectedRow.emit(this.datas[this.datas.length-1]);
  }


  toJsonString(doc): string {
    var tdoc: string = '{"documentuniqueid":"' + doc.documentuniqueid + '","_index":"dossier' + this.dossierService.currentDossierId + '"';
    doc.properties.forEach(element => {
      tdoc += ',"' + element.property.field + '":[{"id":' + element.id + ',"value":"' + element?.value + '"}]'
    });
    tdoc += '}';
    return tdoc;
  }


  /**
   * Dynamsoft_OnReady is called when a WebTwain instance is ready to use.
   * In this callback we do some initialization.
   */
  Dynamsoft_OnReady(): void {
    this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
    this.DWObject.BufferMemoryLimit = 2000; //MB
    this.DWObject.SetLanguage(Dynamsoft.DWT.EnumDWT_Language.French);

    let sources = this.DWObject.GetSourceNames();
    this.selectSources = <HTMLSelectElement>document.getElementById("sources");
    this.selectSources.options.length = 0;
    for (let i = 0; i < sources.length; i++) {
      this.selectSources.options.add(new Option(<string>sources[i], i.toString()));
    }
  }
  /**
   * Acquire images from scanners or cameras or local files
   */
  acquireImage(): void {
    if (!this.DWObject)
      this.DWObject = Dynamsoft.DWT.GetWebTwain();
    if (this.DWObject.SourceCount > 0 && this.DWObject.SelectSourceByIndex(this.selectSources.selectedIndex)) {

      var deviceConfiguration = {
        IfShowUI: true, //document.getElementById('ShowUI').checked, //false,
        PixelType: (this.scanForm?.value["pixeltype"] == "0") ? Dynamsoft.DWT.EnumDWT_PixelType.TWPT_BW :
          (this.scanForm?.value["pixeltype"] == "1") ? Dynamsoft.DWT.EnumDWT_PixelType.TWPT_GRAY : Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB,
        IfAutomaticDeskew: true,
        Resolution: this.scanForm?.value["resolution"],
        //IfFeederEnabled: document.getElementById('ADF').checked,
        IfDuplexEnabled: false,
        //IfAutoDiscardBlankpages: document.getElementById('DiscardBlankPage').checked,
        IfDisableSourceAfterAcquire: true
      };

      const onAcquireImageSuccess = () => {
        this.disableSave = false;
        this.totalImages = this.getTotalImage();
        this.currentImageIndex = this.DWObject.CurrentImageIndexInBuffer + 1;
        this.buffer.push(this.DWObject.CurrentImageIndexInBuffer);
        this.DWObject.CloseSource();
      };
      const onAcquireImageFailure = () => {
        this.DWObject.CloseSource();
      };

      this.DWObject.OpenSource();
      this.DWObject.AcquireImage(deviceConfiguration, onAcquireImageSuccess, onAcquireImageFailure);


    } else {
      alert("No Source Available!");
    }
  }

  save() {
    var that = this;
    var base64Result: any = null;
    this.DWObject.ConvertToBase64(
      this.buffer,
      Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
      function (result, indices, type) {
        base64Result = result.getData(0, result.getLength());
        that.upload(base64Result);
      },
      function (errorCode, errorString) {
        console.log(errorString);
      }
    );
  }

  upload(base64Result) {
    console.log("sending data to elk");
    this.documentService.update(this.document).subscribe(
      doc => {
        console.log("document mis à jour avec succes. Appel ELK ...");
        this.elkService.uploadFile2(base64Result, 'dossier' + this.dossierService.currentDossierId, doc.documentuniqueid)
          .subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                console.log('Request has been made!');
                break;
              case HttpEventType.ResponseHeader:
                console.log('Response header has been received!');
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round(event.loaded / event.total * 100);
                console.log(`Uploaded! ${this.progress}%`);
                break;
              case HttpEventType.Response:
                console.log('Document successfully created!', event.body);

            }
          })
      }),
      error => { console.log("Error occurs when sending the document.") }
  }


  /**
   * Open local images.
   */
  openImage(): void {
    if (!this.DWObject)
      this.DWObject = Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
    this.DWObject.IfShowFileDialog = true;
    /**
     * Note, this following line of code uses the PDF Rasterizer which is an extra add-on that is licensed seperately
     */
    this.DWObject.Addon.PDF.SetConvertMode(Dynamsoft.DWT.EnumDWT_ConvertMode.CM_RENDERALL);
    this.DWObject.LoadImageEx("", Dynamsoft.DWT.EnumDWT_ImageType.IT_ALL,
      () => {
        //success
      }, () => {
        //failure
      });
  }
}
