<h1 mat-dialog-title>AJOUTER UN DOSSIER</h1>
<div mat-dialog-content>

  <div class="form-group">
    <label for="exampleFormControlInput1">Libellé</label>
    <input type="text" class="form-control" [(ngModel)]="dossier.name">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="description">Description</label>
    <input type="text" class="form-control" id="description" [(ngModel)]="dossier.description">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="mailaddress">Adresses mail </label>
    <input type="text" class="form-control" id="mailaddress" [(ngModel)]="dossier.canSendMail">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="telephone">Téléphone </label>
    <input type="text" class="form-control" id="telephone" [(ngModel)]="dossier.smsReceiver">
  </div>
  <div class="form-group">
    <label for="exampleFormControlInput1" id="code">Code</label>
    <input type="text" class="form-control" readonly [(ngModel)]="dossier.code">
  </div>
  <div class="form-group">
    <mat-slide-toggle [(ngModel)]="dossier.codedifAuto">Activer la codification automatique</mat-slide-toggle>
  </div>
  <div class="form-group">
    <mat-slide-toggle [(ngModel)]="dossier.clone">Rendre le dossier duplicable</mat-slide-toggle>
  </div>
  <div class="form-group" *ngIf="dossier.dossierId==null">
    <mat-slide-toggle [(ngModel)]="dossier.dataOnly">Formulaire de donnée uniquement</mat-slide-toggle>
  </div>
  <div class="form-group">
    <mat-slide-toggle [(ngModel)]="dossier.close">Clôturer le dossier</mat-slide-toggle>
  </div>
  <div class="form-group" *ngIf="dossier.parentId==0">
    <label for="exampleFormControlInput1" id="icon">Choisir une icon </label>
    <input type="text" class="form-control" id="icon" [(ngModel)]="dossier.icon" readonly>
    <div class="icon-palette">
      <div *ngFor="let icon of icons">
        <app-feather-icons [icon]="icon" (click)="selectIcon(icon)"></app-feather-icons>
      </div>
    </div>
  </div>



</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Fermer</button>
  <button mat-raised-button color="primary" (click)="save()" tabindex="2">Enregistrer</button>
</div>

<!--
  
<h1 mat-dialog-title>AJOUTER UN DOSSIER</h1>
<div mat-dialog-content>
  <form [formGroup]="dossierForm">
    <div class="form-group">
      <label for="exampleFormControlInput1">Libellé</label>
      <input type="text" class="form-control" [formControlName]="name" >
    </div>
    <div class="form-group">
        <label for="exampleFormControlInput1">Description</label>
        <input type="text" class="form-control" [formControlName]="description" id="description" >
      </div>
    <div class="form-group">
      <label for="exampleFormControlSelect1">Dossier parent</label>
      <select class="form-control" [formControlName]="parentId" id="exampleFormControlSelect1" >
        <option *ngFor="let dos of listDossiers; let i=index" [value]="dos.dossierId"></option>
      </select>
    </div>      
</form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="save()" tabindex="2">Enregistrer</button>
  <button mat-raised-button color="warn" (click)="close()" tabindex="-1">Fermer</button>
</div>

-->