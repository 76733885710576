<div class="d-flex justify-content-end mb-5">
    <button mat-icon-button class="close-button " [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="black">close</mat-icon>
    </button>
</div>
<legend class="scheduler-border">Déplacement de document dans un autre dossier</legend>
<div id="container">
    <form>
        <fieldset class="scheduler-border">

            <ul *ngIf="treeNode">
                <div *ngFor="let node of treeNode" class="procedure-hierarchy">
                    <li class="list-group-item">
                        <div class="group-item">
                            <div class="list-group-item-icon" (click)="toggleChild(node)">
                                <span class="folder-icon" *ngIf="!node.expanded"><i class="fa fa-folder"></i></span>
                                <span class="folder-open-icon" *ngIf="node.expanded"><i
                                        class="fa fa-folder-open"></i></span>
                                <span> {{ node.name }}</span>
                            </div>

                        </div>

                        <app-tree-view *ngIf="node.expand" [treeNode]="node.children"
                            (selectNodeEvent)="selectNode($event)">
                        </app-tree-view>
                    </li>

                </div>
            </ul>
        </fieldset>
        <button style="margin-left: .5em;" class="btn btn-secondary" [mat-dialog-close]="true" (click)="cancel()"><i
                class="fa-solid fa-scanner"></i> Annuler</button>
        <button style="margin-left: .5em;" class="btn btn-secondary" (click)="reclasser()"
            [disabled]="currrentDossier==null || !currrentDossier.selected"><i class="fa-solid fa-scanner"></i>
            Déplacer</button>
    </form>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Veuillez patienter... </p>
</ngx-spinner>