import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { HomeComponent } from './home/home.component';
import { SearchAllFieldsComponent } from './search-all-fields/search-all-fields.component';
import { WidgetsComponent } from './widgets/widgets.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        component: HomeComponent,
      }
    ]
  }
  ,
  {
    path: '',
    children: [
      {
        path: 'search',
        component: SearchComponent,
      }
    ]
  }
  ,
  {
    path: '',
    children: [
      {
        path: 'search/:id',
        component: SearchComponent,
      }
    ]
  },

  {
    path: '',
    children: [
      {
        path: 'widgets/:dossierId',
        component: WidgetsComponent,
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'search/all/:query',
        component: SearchAllFieldsComponent,
      }
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'search/:id?query=:query',
        component: SearchComponent,
      }
    ]
  }, {
    path: '',
    children: [
      {
        path: 'search/:id/:q',
        component: SearchComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserSpaceRoutingModule { }

