import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeInseeEnum } from 'src/app/shared/model/type-insee.enum';
import { TrimestreEnum } from 'src/app/shared/model/trimestre.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndiceInsee } from 'src/app/shared/model/indice-insee';
import { IndiceService } from 'src/app/shared/services/indice.service';

interface ListTrimestre {
  name: string
}
interface ListTypeInsee {
  name: string
}
@Component({
  selector: 'app-indice',
  templateUrl: './indice.component.html',
  styleUrls: ['./indice.component.scss']
})
export class IndiceComponent implements OnInit {

  indiceForm: FormGroup;
  trimestres: ListTrimestre[] = [
    {
      name: TrimestreEnum.T_1
    },
    {
      name: TrimestreEnum.T_2
    },
    {
      name: TrimestreEnum.T_3
    },
    {
      name: TrimestreEnum.T_4
    },
  ]
  typeILCs: ListTypeInsee[] = [
    {
      name: TypeInseeEnum.TYPE_ICC
    },
    {
      name: TypeInseeEnum.TYPE_ILAT
    },
    {
      name: TypeInseeEnum.TYPE_ILC
    },
    {
      name: TypeInseeEnum.TYPE_IRL
    },
  ]
  constructor(private fb: FormBuilder,
    private indiceService: IndiceService,
    public dialogRef: MatDialogRef<IndiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IndiceInsee) {

  }

  ngOnInit(): void {
    this.indiceForm = this.fb.group({
      annee: new FormControl('', [Validators.required]),
      trimestre: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      dateParution: new FormControl('', [Validators.required]),
    })
  }
  get trimestre() {
    return this.indiceForm.get('trimestre');
  }
  get type() {
    return this.indiceForm.get('type');
  }
  get annee() {
    return this.indiceForm.get('annee');
  }
  get value() {
    return this.indiceForm.get('value');
  }
  get dateParution() {
    return this.indiceForm.get('dateParution');
  }

  createIndice() {
    console.log(this.indiceForm?.value)
    let formValue = this.indiceForm?.value;
    this.indiceService.createIndice(this.indiceForm?.value).subscribe(
      (sortie) => {
        console.log(sortie);
        this.dialogRef.close();
      },
      error => (error) => {
        console.log('error ==> ', error
        )
      }
    )
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
