<div class="contenu">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12" *ngIf="resultat?.length > 0; else emptyBlock">
        <div *ngIf="searchInProgress==false">
          <h4>{{resultat.length}} dossier(s) trouvé(s) contenant le terme
            <strong>{{query}}</strong>
          </h4>
        </div>
        <div *ngIf="searchInProgress==true">
          <h4>Recherche en cours...</h4>
        </div>

        <mat-card *ngFor="let element of resultat" class="mt-2">
          <mat-card-header>
            <mat-card-title> <a [routerLink]="'/user/search/'+element.data[0].encoded+'/'+query">
                {{element.title}}</a></mat-card-title>
            <mat-card-subtitle> {{element.description}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
          </mat-card-content>
        </mat-card>

      </div>
      <ng-template #emptyBlock>
        <h4>Aucun document trouvé pour le terme <strong>{{query}}</strong></h4>
      </ng-template>
    </div>
  </div>