import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SharedModule } from "./shared/shared.module";
import { FormsModule } from '@angular/forms';
import { UserSpaceModule } from './user-space/user-space.module';
import { RequestService } from './shared/services/request.service';
import { DossierService } from './shared/services/dossier.service';
import { SampleModule } from './components/sample/sample.module';
import { AdminModule } from './admin/admin.module';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { PropertyService } from './shared/services/property.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ElkService } from './shared/services/elk.service';
import { AuthService } from './shared/services/auth.service';
import { DefaultOAuthInterceptor } from './shared/services/interceptor.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { NgxCkeditorModule } from "ngx-ckeditor4";

registerLocaleData(fr);
export function init_vusadoc(authConfigService: AuthService) {
  return () => authConfigService.initConfiguration();
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    UserSpaceModule,
    SampleModule, AdminModule, HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://vocalex.vusadoc.fr/backend'],
        sendAccessToken: true
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    NgxCkeditorModule.forRoot({
      url: "https://cdn.bootcss.com/ckeditor/4.11.3/ckeditor.js",
      config: {
        filebrowserUploadMethod: "xhr",
        filebrowserUploadUrl: "http://127.0.0.1:8000/index/index/uploads"
      }
    }),
  ],
  providers: [RequestService, AuthService,
    DossierService,
    PropertyService,
    ElkService,
    {
      provide: APP_INITIALIZER, useFactory: init_vusadoc,
      multi: true, deps: [AuthService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultOAuthInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
