import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { Reference } from 'src/app/shared/model/reference';
import { OperationService } from 'src/app/shared/services/operation.service';
import { ReferenceService } from 'src/app/shared/services/reference.service';
import { Operation } from '../../shared/model/operation'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Document } from '../../shared/model/document';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit {

  displayedColumns: any[] = [
    { field: 'date' }, { field: 'numCompte', headerName: 'Numéro de compte', }, { field: 'libel', headerName: 'Description', }, { field: 'montant' }, { field: 'sens' },
    {
      headerName: 'Actions',
      field: 'supp',
      cellRenderer: function (params) {
        return '<a style="cursor: pointer;" title="Supprimer"><i class="icon-trash"></i> </a> ';
      }
    },
  ];

  classes: String[][] =
    [["6019", "Crédit"],
    ["6029", "Crédit"],
    ["6049", "Crédit"],
    ["6059", "Crédit"],
    ["6089", "Crédit"],
    ["603", ""],
    ["6", "Débit"],
    ["7019", "Débit"],
    ["7029", "Débit"],
    ["7039", "Débit"],
    ["7049", "Débit"],
    ["7059", "Débit"],
    ["7069", "Débit"],
    ["73", ""],
    ["7", "Crédit"],
    ["81", "Débit"],
    ["83", "Débit"],
    ["85", "Débit"],
    ["87", "Débit"],
    ["89", "Débit"],
    ["82", "Crédit"],
    ["84", "Crédit"],
    ["86", "Crédit"],
    ["88", "Crédit"]
    ]
  operationList: Operation[] = [];
  api: any;
  references: any[] = [];

  filteredOptions: Observable<Reference[]>;
  referenceComptes: Reference[] = [];
  referenceComptesSup: Reference[] = [];

  operation: Operation = { date: new Date(), numCompte: null, numCompteSec: null, libel: null, montant: "0", numPiece: null, documentuniqueId: null, journalDocId: null, sens: 'c', journal: null, delete: false };
  operationForm: FormGroup;
  sens: string;
  contre: string;
  dossier: any;
  document: Document;
  sensOptions: Array<string> = ["Crédit", "Débit"];
  journalOptions: Observable<Reference[]>;
  compteOptions: Observable<Reference[]>;
  compteSupOptions: Observable<Reference[]>;
  filtered2Options: Observable<string[]>;

  loadingTemplate: string;
  noRowsTemplate: string;

  constructor(private toastrService: ToastrService, private fb: FormBuilder,
    private operationService: OperationService,
    private dialogRef: MatDialogRef<OperationComponent>,
    private refernceService: ReferenceService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dossier = data.dossier;
    this.document = this.getDocument(this.data?.dossier?.documents, this.data?.row.documentuniqueid);
    console.log("Data*********", data)
    this.loadingTemplate =
      `<span class="ag-overlay-loading-center">cgargement en cours...</span>`;
    this.noRowsTemplate =
      `"<span">aucune opération à afficher</span>"`;
  }

  ngOnInit(): void {
    this.initForm();
    this.operationService.getOperations(this.data?.row.documentuniqueid).subscribe(res => {
      console.log("Les opérations sont récupérées avec succès", res);
      if (res != null) {
        this.operationList = res;
      }
      const defaultJournal = this.operationList.length > 0 ? this.operationList[0].journal : '';
      this.operationForm.get('journal').setValue(defaultJournal);
      //this.dataSource = new MatTableDataSource<any>(this.operationList);
    }, error => {
      console.log("Une erreur s'est produite");
    });

    this.journalOptions = this.setRefList('Journaux');

    //this.compteSupOptions = this.setRefList('Comptes secondaires');

    this.compteOptions = this.operationForm.get('numCompte').valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filter(value || '', this.setRefList('Comptes principaux')))
    );
    this.compteSupOptions = this.operationForm.get('numCompteSec').valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filter(value || '', this.setRefList('Comptes secondaires')))
    );
  }

  private _filter(value: string, options: Observable<Reference[]>): Observable<Reference[]> {
    const filterValue = value.toLowerCase();
    return options.pipe(map(option => option.filter(op => op.name.toLowerCase().includes(filterValue))));
  }
  initForm() {

    this.operationForm = new FormGroup({
      date: new FormControl(''),
      numCompte: new FormControl('', Validators.required),
      numCompteSec: new FormControl(''),
      libel: new FormControl(''),
      montant: new FormControl(0, Validators.required),
      sens: new FormControl(''),
      journal: new FormControl(''),
      documentuniqueId: new FormControl(''),
      journalDocId: new FormControl(this.data?.row.documentuniqueid)
    })

    this.operationForm.get("numCompte").valueChanges.subscribe(x => {
      console.log('numCompte value changed', x);
      for (var i = 0; i < this.classes.length; i++) {
        if (x.startsWith(this.classes[i][0]))
          this.operationForm.get("sens").setValue(this.classes[i][1]);
      }
    })

  }
  onGridReady(params: any) {
    this.api = params.api;
  }
  /**
   * on click
   * @param e 
   */
  onCellClicked(e: any) {
    if (e.colDef.field === 'supp') {
      const rowIndex = e.rowIndex;
      const ope: Operation = e.data;
      this.api.updateRowData({ remove: [ope] });
      ope.delete = true;
      if (ope.documentuniqueId == null) {
        this.operationList.splice(rowIndex, 1);
      }
    }
  }

  reset() {
    this.operationForm.get('numCompte').setValue('');
    this.operationForm.get('numCompteSec').setValue('');
    this.operationForm.get('libel').setValue('');
    this.operationForm.get('montant').setValue('');
    this.operationForm.get('sens').setValue('');
  }

  /**
   * Close modale
   */
  closeModale() {
    this.dialogRef.close();
  }
  setRefList(name: string) {
    return this.refernceService.getListReferenceByParentName(name);
  }
  addOp() {
    const ope = {
      date: this.operationForm.controls.date?.value,
      documentuniqueId: null,
      journal: this.operationForm.controls.journal?.value,
      journalDocId: this.operationForm.controls.journalDocId?.value,
      libel: this.operationForm.controls.libel?.value,
      montant: String(this.operationForm.controls.montant?.value),
      numCompte: this.operationForm.controls.numCompte?.value,
      numCompteSec: this.operationForm.controls.numCompteSec?.value,
      numPiece: '',
      sens: this.operationForm.controls.sens?.value,
      delete: false
    };
    this.operationList.push(ope);
    this.api.updateRowData({ add: [ope] });
    console.log("Contenu de la liste ", this.operationList);

  }

  /**
   * Comptabilise
   */
  account() {
    //1. Vérifie si la répartition est équilibrée
    var totalCredit: number = 0;
    var totalDebit: number = 0;
    this.operationList.forEach(p => {
      if (p.sens == 'Crédit' && !p.delete) {
        totalCredit += Number(p.montant);
      } else if (p.sens == 'Débit' && !p.delete) {
        totalDebit += Number(p.montant);
      }
      p.numPiece = this.getDocument(this.data?.dossier?.documents, this.data?.row.documentuniqueid)?.codification;
      p.journalDocId = this.data?.row.documentuniqueid;
    });
    if (Math.abs(totalCredit - totalDebit) > 0.01) {
      this.toastrService.error("La répartition n'est pas équilibrée! Veuillez la revoir.");
      return;
    }
    //2. La répartition est équilibrée. On peut enregistrer dans le journal
    this.operationService.saveAllOperation(this.operationList).subscribe(res => {
      console.log("Les opérations sont sauvegardés avec succès", res);
      this.operationList = res;
      this.toastrService.success("Les opérations sont sauvegardés avec succès.");
    }, error => {
      console.log("Une erreur s'est produite");
    });

  }

  getDocument(documents: Document[], documentUniqId: string) {
    return documents.find(d => d.documentuniqueid === documentUniqId);
  }

}
