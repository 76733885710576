import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Dossier } from 'src/app/shared/model/dossier';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentService } from 'src/app/shared/services/document.service';
import { RequestService } from 'src/app/shared/services/request.service';
import { NgxSpinnerService } from "ngx-spinner";
import { SelectionModel } from '@angular/cdk/collections';
import { DocumentLinkDto } from 'src/app/shared/model/document-link-dto';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-link-document',
  templateUrl: './link-document.component.html',
  styleUrls: ['./link-document.component.scss']
})
export class LinkDocumentComponent implements OnInit {
  entries: any[] = [];
  selecttedEntries: any[] = [];
  displayedColumns: string[] = [];
  columns: [] = [];
  dataSource = new MatTableDataSource<any>(this.entries);
  selection = new SelectionModel<any>(true, []);
  selectGroup: FormGroup;
  docId: any;
  constructor(private requestService: RequestService, private spinnerService: NgxSpinnerService,
    private fb: FormBuilder, private documentService: DocumentService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<LinkDocumentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.data = data.data;
    this.docId = data.id;
  }
  selectionChange() {
    this.launch(this.selectGroup.get("selected")?.value.dossierEncoded);
  }
  ngOnInit(): void {
    this.initForm();
  }
  close(): void {
    this.dialogRef.close();
  }
  initForm() {
    this.selectGroup = this.fb.group({
      selected: [null, Validators.required]
    })
  }
  launch(id: any) {
    this.spinnerService.show();
    this.requestService.searchResult(
      id, this.requestService.requestValue, null).subscribe(res => {
        this.displayedColumns = [];
        this.displayedColumns.splice(0, this.displayedColumns.length);
        this.displayedColumns.push('select')
        for (var i = 0; i < res.header.length; i++) {

          if (res.header[i].visibleOnList) {
            this.displayedColumns.push(res.header[i].prop);
          }
        }
        this.columns = res.header;
        this.dataSource = new MatTableDataSource<any>(res.data);
        this.spinnerService.hide();
      });

  }
  matCheckboxChange(event, row) {
    if (event) {
      this.selection.toggle(row)
    }
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  createLink() {
    let docLink: DocumentLinkDto = new DocumentLinkDto();
    docLink.sourceId = this.docId;
    console.log(this.selection.selected);
    docLink.destinationId = this.selection.selected.map(d => d.documentuniqueid);
    this.documentService.createLink(docLink).subscribe(res => {
      console.log(res);
      this.toastrService.success("Les liens sont créés avec succès");
    },
      error => { this.toastrService.error("Une erreur s'est produite lors de la création des liens"); });

  }

}
