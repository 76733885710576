import { Component, Input, OnInit } from '@angular/core';
import { BarChart, DoughnutChart, PieChart, RadarChart, Rapport } from '../../shared/model/rapport';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-tab-rapport',
  templateUrl: './tab-rapport.component.html',
  styleUrls: ['./tab-rapport.component.scss']
})
export class TabRapportComponent implements OnInit {
  existSommable: boolean = true;
  @Input() columnList: JSON[];
  @Input() columnTotal: JSON[];
  @Input() dataSource: MatTableDataSource<any>;
  @Input() filteredDataSource: MatTableDataSource<any>;
  filterReport: JSON[] = JSON.parse('[{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null}]');

  selectedComp: JSON[];
  barChart: BarChart
  doughnutChart: DoughnutChart;
  pieChart: PieChart;
  radarChart: RadarChart;
  constructor() {
    this.barChart = new BarChart();
    this.doughnutChart = new DoughnutChart();
    this.pieChart = new PieChart();
    this.radarChart = new RadarChart();

  }

  setDataSource() {
    this.filterReport = JSON.parse('[{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null},{"name":null}]');
    //this.filteredDataSource =  Object.assign({}, this.dataSource); 
    this.filteredDataSource.data = [];
    this.filterDataSource();
    this.barChart.init(false);

    this.doughnutChart.init(false);
    this.pieChart.init(false);
    this.radarChart.init(false);
  }
  filterDataSource() {
    var bool: boolean = true;
    //this.filteredDataSource.data.;
    console.log("filterReport", this.filterReport);
    this.filteredDataSource.data = this.dataSource?.data?.filter((item) => {
      bool = true;
      this.filterReport.forEach(element => {
        bool = (element["name"] != null) ? bool && item[element["prop"]][0].value.toLowerCase().indexOf(element['value'].toLowerCase()) >= 0 : bool;
      });

      return bool;
    })
  }

  ngOnInit(): void {
    //this.columnTotal.push();

  }
}
