import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';
import { Utilisateur } from 'src/app/shared/model/utilisateur';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilisateurService } from '../../shared/services/utilisateur.service';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss']
})
export class UtilisateurComponent implements OnInit {

  constructor(private fb: FormBuilder, private utilisateurService: UtilisateurService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<UtilisateurComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Utilisateur) {
    this.user = data;
  }

  formGroup: FormGroup;
  user: Utilisateur;

  ngOnInit(): void {
    this.initForm();
  }

  /**
 * Initialisation du formulaire de création de properties
 */
  initForm() {
    this.formGroup = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      enabled: [true],
      username: [null, [Validators.required]],
      enterprise: [null, [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    })
  }

  create() {
    this.utilisateurService.create(this.formGroup?.value, this.authService.user).subscribe(
      res => {
        this.dialogRef.close();
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
